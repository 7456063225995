import React, {useState} from 'react';
import ReactGA from 'react-ga';
import './App.css';
/*
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
*/

function About() {

    const iosopen = () => {
        ReactGA.event({
            category: 'Interaction',
            action: 'Opened the iOS download page(consumer_n_v1)'
        });
        window.open("https://apps.apple.com/us/app/%ED%94%BD%EA%B8%80/id1542494927");
    }

    const androidopen = () => {
        ReactGA.event({
            category: 'Interaction',
            action: 'Opened the Android download page(consumer_n_v1)'
        });
        window.open("https://play.google.com/store/apps/details?id=com.pickgleapp");
    }

    const linkopen = () => {
      ReactGA.event({
          category: 'Interaction',
          action: 'Opened the registration page(consumer_n_v1)'
      });
      window.open("https://tally.so/r/wae0Nv");
  }



    return (
      <>
        <div className="about_body">
          <div className="about_form">
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp1%2Flp1.png?alt=media&token=7a30453a-a24b-4e1a-aab4-fa8e21aab760&_gl=1*1iz2cr7*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE4MjE5LjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp1%2Flp2.png?alt=media&token=7be50901-4f47-49b7-9e92-27039deff913&_gl=1*1tuhib8*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE4MjMyLjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp1%2Flp3.png?alt=media&token=31c031cf-a7c8-4c8a-960d-4374654c04bc&_gl=1*jyvphv*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE4OTcwLjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp1%2Flp4.png?alt=media&token=e68b7536-bb0e-4a48-bca4-1b75836c5b1d&_gl=1*1xw85ly*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE4MjY2LjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp1%2Flp5.png?alt=media&token=2a455a9f-c87a-4e96-9d94-8cbff1302d70&_gl=1*1q8x65a*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE4Mjc5LjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_3"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp1%2Flp6.png?alt=media&token=11cb661f-975d-4b49-b44c-9b21dd3d774f&_gl=1*1fcv2zp*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE4Mjk0LjAuMC4w"
                alt="pickgle intro"
              ></img>
            <div className="write_forms_buttons2">
              <button
                className="about_button_focus_2"
                onClick={linkopen}
              >
                프로그램 사전 등록하기
              </button>
              <button
                className="about_button"
                onClick={iosopen}
              >
                <img className="about_logo" src = "https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/apple.png?alt=media&token=6a3c7745-3491-4f33-921e-b7c79cc0de56"/>
                App Store
              </button>
              <button className="about_button" onClick={androidopen}>
                <img className="about_logo" src = "https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/googleplay.png?alt=media&token=6783ccfe-0516-45ce-8624-a3a18fde81af"/>
                Google Play
              </button>
            </div>
          </div>
        </div>
      </>
    );
}

export default About;