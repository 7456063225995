import React, {useState} from 'react';
import ReactGA from 'react-ga';
import './App.css';
/*
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
*/

function About() {

    const iosopen = () => {
        ReactGA.event({
            category: 'Interaction',
            action: 'Opened the iOS download page(consumer_v4)'
        });
        window.open("https://apps.apple.com/us/app/%ED%94%BD%EA%B8%80/id1542494927");
    }

    const androidopen = () => {
        ReactGA.event({
            category: 'Interaction',
            action: 'Opened the Android download page(consumer_v4)'
        });
        window.open("https://play.google.com/store/apps/details?id=com.pickgleapp");
    }

    const linkopen = () => {
      ReactGA.event({
          category: 'Interaction',
          action: 'Opened the registration page(consumer_v4)'
      });
      window.open("https://forms.gle/MgT2EnsXmqyWQ3Cw8");
  }



    return (
      <>
        <div className="about_body">
          <div className="about_form">
          <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping_20230425_3.jpg?alt=media&token=f4334550-a942-4d2f-9b67-fc035619320f"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/%E1%84%89%E1%85%B3%E1%86%AF%E1%84%85%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B31.jpeg?alt=media&token=59e09223-7d43-4f75-a7bc-6a203685a9ee"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/%E1%84%89%E1%85%B3%E1%86%AF%E1%84%85%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B32.jpeg?alt=media&token=456ddb1a-fa65-463f-89d5-5aa043b1ef2d"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/%E1%84%89%E1%85%B3%E1%86%AF%E1%84%85%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B33.jpeg?alt=media&token=0dda7e5f-94be-44e7-bca0-e304e0a27b0a"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/%E1%84%89%E1%85%B3%E1%86%AF%E1%84%85%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B34.jpeg?alt=media&token=bb255eff-4d6d-4e98-8ab1-b35692500db7"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_3"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/%E1%84%89%E1%85%B3%E1%86%AF%E1%84%85%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%83%E1%85%B35.jpeg?alt=media&token=3ac0fc99-c037-43f4-895e-c9c7cde62a5d"
                alt="pickgle intro"
              ></img>
            <div className="write_forms_buttons2">
              <button
                className="about_button_focus_2"
                onClick={linkopen}
              >
                프로그램 사전 신청하기
              </button>
              <button
                className="about_button"
                onClick={iosopen}
              >
                <img className="about_logo" src = "https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/apple.png?alt=media&token=6a3c7745-3491-4f33-921e-b7c79cc0de56"/>
                App Store
              </button>
              <button className="about_button" onClick={androidopen}>
                <img className="about_logo" src = "https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/googleplay.png?alt=media&token=6783ccfe-0516-45ce-8624-a3a18fde81af"/>
                Google Play
              </button>
            </div>
          </div>
        </div>
      </>
    );
}

export default About;