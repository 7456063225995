import React, {useState, useEffect} from 'react';
import ReactGA from 'react-ga';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import "firebase/functions";
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

const PanelComponent = (props) => {
  const data = props.data;
  const listinfo = data.listinfo;
  const liststats = data.liststats;
  
  return (
    <div className="web_list_panelcomponent">
      <div className="web_list_id_row">
        <img className="web_list_profile_thumbnail" src={listinfo.userinfo.profile_thumbnail}/>
            <div className={listinfo.list_image?"web_list_username_w":'web_list_username'}>
              {listinfo.userinfo.nickname}
            </div>
            <div className={listinfo.list_image?"web_list_nickname_w":'web_list_nickname'}>
              (@{listinfo.userinfo.username})
            </div>
      </div>
      <div className={listinfo.list_image?"web_list_listname_w":"web_list_listname"}>
        {listinfo.list_name}
      </div>
      <div className={listinfo.list_image?"web_list_listexp_w":"web_list_listexp"}>
        {listinfo.list_exp}
      </div>
      <div className={listinfo.list_image?'web_list_stats_row_w':'web_list_stats_row'}>
        <div className={listinfo.list_image?'web_list_stats_button_w':'web_list_stats_button'}>
          <div className='web_list_stats_text_button'>
            <div className={listinfo.list_image?'web_list_stats_text_w':'web_list_stats_text'}>
              리뷰
            </div>
            <div className={listinfo.list_image?'web_list_stats_text2_w':'web_list_stats_text2'}>
              {liststats.postcount}
            </div>
          </div>
          <div className="web_list_stats_divider"/>
          <div className='web_list_stats_text_button'>
            <div className={listinfo.list_image?'web_list_stats_text_w':'web_list_stats_text'}>
              구독자
            </div>
            <div className={listinfo.list_image?'web_list_stats_text2_w':'web_list_stats_text2'}>
              {liststats.subscount}
            </div>
          </div>
          <div className="web_list_stats_divider"/>
          <div className='web_list_stats_text_button'>
            <div className={listinfo.list_image?'web_list_stats_text_w':'web_list_stats_text'}>
              리액션
            </div>
            <div className={listinfo.list_image?'web_list_stats_text2_w':'web_list_stats_text2'}>
              {liststats.recocount}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const TopPanel = (props) => {
  const data = props.data;

  return (
    data&&data.listinfo.list_image?
    <div className="web_list_top_panel"
    style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${data.listinfo.list_image})`,
      backgroundSize: 'cover', // Maintain image aspect ratio
      backgroundPosition: 'center', // Center the image
    }}
    >
      <PanelComponent data={data}/>
    </div>
    :
    <div className="web_list_top_panel">
      <PanelComponent data={data}/>
    </div>
  )
}

const PostsPreview = (props) => {
  const postinfo = props.postinfo;
  
  return (
    <div className="web_list_post_preview">
      <div className="web_list_left_panel">
        <div className="web_list_text_panel">
          <div className="web_list_review_target">
            {postinfo.review_target}
          </div>
          <div className="web_list_rating">
              <FontAwesomeIcon
                style={{ color: "#ffc900", marginRight: 0.5 }}
                icon={faStar}
                size={14}
              />
            {postinfo.rating.toFixed(1)}
          </div>
        </div>
        <div className="web_list_tag_row">
          {postinfo.list_tags.map(lt=>
          <div className="web_list_tag">
            #{lt}
          </div>
          )}
        </div>
      </div>
      {postinfo.photolink ? (
        <div
          className="web_list_review_thumbnail"
          style={{
            backgroundImage: `url(${postinfo.photolink[0]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      ) : null}
    </div>
  );
}

const BottomPost = (props) => {
  const data = props.data;
  const listinfo = data.listinfo;
  const [posts, setPosts] = useState(null);

  useEffect(()=>{
    if (data.posts) {

      const posts = [...data.posts].map(post => ({
        ...post,
        time: new Date(post.time._seconds * 1000) // Convert Unix timestamp to Date object
      }));

      const sortedData = posts.sort((a, b) => (a.time > b.time) ? -1 : 1);
      setPosts(sortedData);
    }
  },[data.posts])

  if (listinfo.public) {
    return (
      <div className="web_list_bottom_post">
        {posts?
        posts.map(pt=>
          <PostsPreview postinfo={pt} key={pt.postnum}/>  
        )
        :
        null}
      </div>
    );
  } else {
    return (
      <div className="web_list_bottom_post">
        <div style={{height:'100vh',width:'100%',justifyContent:'center',alignItems:'center',fontSize:14,alignSelf:'center',alignContent:'center',textAlign:'center'}}>
          비공개 리스트입니다. 앱 설치 후 구독을 요청해 보세요!
        </div>
      </div>

    )
  }

}

function List(props) {

  const [ data, setData ] = useState({loading:true, data:null})
  const history = useHistory();
  const listid = props.listid;
  
  useEffect(() => {
    const listQuery = firebase.app().functions('asia-northeast3').httpsCallable('listInfoHandler');
    
    listQuery({ listid: listid })
    .then(result => {
      if (result.data.listinfo) {
        setData({loading:false,data:result.data})
      } else {
        history.push('/about');
      }
    })
    .catch(error => {
      console.error('Error calling Firebase function:', error);
      history.push('/about');
    });

    ReactGA.event({
      category: 'Interaction',
      action: 'Opened the list link'
    });
  }, []);

  const appInstallClick = () => {
    const userAgent = navigator.userAgent;

    if (userAgent && /android/i.test(userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=com.pickgleapp', '_blank')
    } else if (userAgent && /iPad|iPhone|iPod/i.test(userAgent)) {
      window.open('https://apps.apple.com/us/app/%ED%94%BD%EA%B8%80/id1542494927', '_blank')
    } else {
      window.open('bit.ly/3HmEQIN', '_blank');
    }

    ReactGA.event({
      category: 'Interaction',
      action: 'Opened the download link from list link'
    });
  }

    if (data.loading) {
      return (
        <div className="layer_spinner"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
      )
    }

    return (
        <div className="web_list">
          <TopPanel data={data.data}/>
          <BottomPost data={data.data}/>
          <div className='web_floating_action_button_container'>
            <div className='web_floating_action_button_text'>
              픽글 앱을 설치하시고 모든 기능을 사용해 보세요!
            </div>
            <button className='web_floating_button' onClick={appInstallClick}>
              앱 설치하러 가기
            </button>
          </div>
        </div>
    );
}

export default List;