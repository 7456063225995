import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import firebase from 'firebase/app';
import 'firebase/auth';        
import 'firebase/storage';     
import 'firebase/firestore';    


const config={
    apiKey: "AIzaSyB4lsqSt5GYyr-L8fynaudzTPjwjDc4zy4",
    authDomain: "pickgle-new.firebaseapp.com",
    projectId: "pickgle-new",
    storageBucket: "pickgle-new.appspot.com",
    messagingSenderId: "238001649920",
    appId: "1:238001649920:web:6ae693adc22e0ef42c9be5",
    measurementId:"UA-151565309-1"
};

firebase.initializeApp(config);

ReactDOM.render(
<App />
, 
document.getElementById('root'));