import React, {useState, useEffect} from 'react';
import ReactGA from 'react-ga';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import "firebase/functions";
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
/*
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
*/

function Post(props) {

  const [ data, setData ] = useState({loading:true, data:null});

  const history = useHistory();
  const postid = props.postid;
  
  useEffect(() => {
    const postQuery = firebase.app().functions('asia-northeast3').httpsCallable('postInfoHandler');
    
    postQuery({ postid: postid })
      .then(result => {
        if (result.data.post) {
          setData({loading:false,data:result.data})
        } else {
          history.push('/about');
        }
      })
      .catch(error => {
        console.error('Error calling Firebase function:', error);
      });
  }, []);

  const appInstallClick = () => {
    const userAgent = navigator.userAgent;

    if (userAgent && /android/i.test(userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=com.pickgleapp', '_blank')
    } else if (userAgent && /iPad|iPhone|iPod/i.test(userAgent)) {
      window.open('https://apps.apple.com/us/app/%ED%94%BD%EA%B8%80/id1542494927', '_blank')
    } else {
      window.open('bit.ly/3HmEQIN', '_blank');
    }

    ReactGA.event({
      category: 'Interaction',
      action: 'Opened the download link from list link'
    });
  }

  if (data.loading ) {
    return (
      <div className="layer_spinner"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
    )
  } else {
    return (
        <div className='web_post'>
          {data.data.post.public?
          <>
            <div className="web_post_id_row">
                <img className="web_list_profile_thumbnail" src={data.data.post.userinfo.profile_thumbnail}/>
                    <div className={'web_list_username'}>
                      {data.data.post.userinfo.nickname}
                    </div>
                    <div className={'web_list_nickname'}>
                      (@{data.data.post.userinfo.username})
                    </div>
              </div>
              {data.data.post.photolink&&data.data.post.photolink[0].includes("firebase")?
                <img
                  src={data.data.post.photolink[0].replace('_300x300.','.')}
                  style={{
                    width:'100vw',
                    maxWidth:550,
                    height:'100vw',
                    maxHeight:550,
                    backgroundColor:'lightgrey',
                    zIndex:99,
                    objectFit:'cover',
                    marginTop:15
                  }}
                />                    
                :
              null}
              <div className='web_post_review_target_row'>
                <div className='web_post_review_target'>
                  {data.data.post.review_target}
                </div>
                <div className='web_post_rating'>
                  <div className='web_post_rating_text'>
                    <FontAwesomeIcon icon={faStar}/>
                    {" "}{data.data.post.rating.toFixed(1)}
                  </div>
                </div>
              </div>
              {data.data.post.list_tags && data.data.post.list_tags.length>0?
              <div className="web_post_tags_row">
                {data.data.post.list_tags.map((lt,index)=>
                  <div className='web_post_list_tag' key={index}>
                    # {lt}
                  </div>
                )}
              </div>
              :
              null
              }
              <div className='web_post_review_content'>
                {data.data.post.review_content}
              </div>
          </>
          :
              <div style={{height:'100vh',width:'100%',justifyContent:'center',alignItems:'center',fontSize:14,alignSelf:'center',alignContent:'center',textAlign:'center',lineHeight:'80vh'}}>
                비공개 게시물입니다. 앱 설치 후 구독을 요청해 보세요!
              </div>
            }
              <div className='web_floating_action_button_container'>
                <div className='web_floating_action_button_text'>
                  픽글 앱을 설치하시고 모든 기능을 사용해 보세요!
                </div>
                <button className='web_floating_button' onClick={appInstallClick}>
                  앱 설치하러 가기
                </button>
              </div>
        </div>
    );

  }

}

export default Post;