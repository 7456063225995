import React, {useState} from 'react';
import ReactGA from 'react-ga';
import './App.css';
/*
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
*/

function About() {

    const iosopen = () => {
        ReactGA.event({
            category: 'Interaction',
            action: 'Opened the iOS download page(v3)'
        });
        window.open("https://apps.apple.com/us/app/%ED%94%BD%EA%B8%80/id1542494927");
    }

    const androidopen = () => {
        ReactGA.event({
            category: 'Interaction',
            action: 'Opened the Android download page(v3)'
        });
        window.open("https://play.google.com/store/apps/details?id=com.pickgleapp");
    }

    const linkopen = () => {
      ReactGA.event({
          category: 'Interaction',
          action: 'Opened the registration page(v3)'
      });
      window.open("https://forms.gle/idQsdzRXgPb6uVTy8");
  }



    return (
      <>
        <div className="about_body">
          <div className="about_form">
          <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping_20230425_3.jpg?alt=media&token=f4334550-a942-4d2f-9b67-fc035619320f"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pt05051.jpeg?alt=media&token=6e17fd8a-ae9a-4e1b-ba3a-90e6f5830387"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pt05052.jpeg?alt=media&token=932c3127-14e0-4f28-a2c3-97d965f7de42"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pt05053.jpeg?alt=media&token=928ff1f3-4b3d-47bc-8185-98200f3a5b60"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pt05054.jpeg?alt=media&token=1678c9b4-a84c-4296-a939-0687d8a8ac49"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pt05055.jpeg?alt=media&token=970ecd8d-9b8e-4e1e-9a31-5641c00843a9"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_3"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pt05056.jpeg?alt=media&token=86227c17-5bfb-4f74-80c1-01dcdc93d65e"
                alt="pickgle intro"
              ></img>
            <div className="write_forms_buttons2">
              <button
                className="about_button_focus_2"
                onClick={linkopen}
              >
                프로그램 사전 신청하기
              </button>
              <button
                className="about_button"
                onClick={iosopen}
              >
                <img className="about_logo" src = "https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/apple.png?alt=media&token=6a3c7745-3491-4f33-921e-b7c79cc0de56"/>
                App Store
              </button>
              <button className="about_button" onClick={androidopen}>
                <img className="about_logo" src = "https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/googleplay.png?alt=media&token=6783ccfe-0516-45ce-8624-a3a18fde81af"/>
                Google Play
              </button>
            </div>
          </div>
        </div>
      </>
    );
}

export default About;