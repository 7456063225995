import React, {useState} from 'react';
import ReactGA from 'react-ga';
import './App.css';
/*
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
*/

function About() {

    const iosopen = () => {
        ReactGA.event({
            category: 'Interaction',
            action: 'Opened the iOS download page(consumer_n_v2)'
        });
        window.open("https://apps.apple.com/us/app/%ED%94%BD%EA%B8%80/id1542494927");
    }

    const androidopen = () => {
        ReactGA.event({
            category: 'Interaction',
            action: 'Opened the Android download page(consumer_n_v2)'
        });
        window.open("https://play.google.com/store/apps/details?id=com.pickgleapp");
    }

    const linkopen = () => {
      
      ReactGA.event({
          category: 'Interaction',
          action: 'Opened the registration page(consumer_n_v2)'
      });
      
      window.open("https://tally.so/r/w8zGoO");
  }



    return (
      <>
        <div className="about_body">
          <div className="about_form">
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp2%2Flp1.png?alt=media&token=4cbc71d3-c5b3-4c6d-a7ee-eb847f650c02&_gl=1*1dyqqrb*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE5Mjc4LjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp2%2Flp2.png?alt=media&token=d4d41fd1-9caf-48fa-b8b9-22512d8a86a8&_gl=1*1d1hohs*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE5Mjk5LjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp2%2Flp3.png?alt=media&token=cee18961-549c-496d-bb52-51c410d248f6&_gl=1*fl93gm*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE5MzM5LjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp2%2Flp4.png?alt=media&token=fd9d845a-38a2-4499-8d88-4e83f784ad89&_gl=1*mnk2xi*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE5MzU1LjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp2%2Flp5.png?alt=media&token=d3504a10-e802-42e7-ac68-8eeb18e91064&_gl=1*vnhual*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE5MzY2LjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_3"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp1%2Flp6.png?alt=media&token=11cb661f-975d-4b49-b44c-9b21dd3d774f&_gl=1*1fcv2zp*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE4Mjk0LjAuMC4w"
                alt="pickgle intro"
              ></img>
            <div className="write_forms_buttons2">
              <button
                className="about_button_focus_2"
                onClick={linkopen}
              >
                프로그램 사전 등록하기
              </button>
              <button
                className="about_button"
                onClick={iosopen}
              >
                <img className="about_logo" src = "https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/apple.png?alt=media&token=6a3c7745-3491-4f33-921e-b7c79cc0de56"/>
                App Store
              </button>
              <button className="about_button" onClick={androidopen}>
                <img className="about_logo" src = "https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/googleplay.png?alt=media&token=6783ccfe-0516-45ce-8624-a3a18fde81af"/>
                Google Play
              </button>
            </div>
          </div>
        </div>
      </>
    );
}

export default About;