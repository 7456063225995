import React, {useState, useEffect, Suspense } from 'react';
import { BrowserRouter as  Router, Route, Switch, useLocation, withRouter, Redirect, match } from "react-router-dom";
import { LastLocationProvider } from 'react-router-last-location';
import About from "./routes/About";
import PL from "./routes/PL";
import PL2 from "./routes/PL2";
import PL3 from "./routes/PL3";
import PL4 from "./routes/PL4";
import PL5 from "./routes/PL5";
import PR from "./routes/PR";
import PR2 from "./routes/PR2";
import PR3 from "./routes/PR3";
import PR4 from "./routes/PR4";
import PR5 from "./routes/PR5";
import PRN1 from "./routes/PRN1";
import PRN2 from "./routes/PRN2";
import PRN3 from "./routes/PRN3";
import Profile from "./routes/Profile";
import List from "./routes/List";
import Post from "./routes/Post";

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history'
//import Helmetcontainer from './components/Helmetcontainer';


ReactGA.initialize('UA-151565309-1');
const history = createBrowserHistory();
history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search)
})

function App() {
  
  useEffect(()=>{
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[])



  /*
        <Route
          path="/:urlParam"
          render={({ match }) => {
            const { urlParam } = match.params;
            if (urlParam.includes('listid')) {
              // URL contains 'listid', render ListIdPage
              return <ListIdPage />;
            }
            
            // Handle other cases or redirect to a different page
            return <Redirect to="/otherPage" />;
          }}
        />
  */

  return (
  <Router history={history}>
    <LastLocationProvider>
      <Suspense fallback={<div className="layer_spinner"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>}>
      <Switch>
        <Route path="/about" exact={true} component={About}/>
        <Route path="/premium_list" exact={true} component={PL}/>
        <Route path="/premium_list_2" exact={true} component={PL2}/>
        <Route path="/premium_list_3" exact={true} component={PL3}/>
        <Route path="/premium_list_4" exact={true} component={PL4}/>
        <Route path="/premium_list_5" exact={true} component={PL5}/>
        <Route path="/premium_review" exact={true} component={PR}/>
        <Route path="/premium_review_2" exact={true} component={PR2}/>
        <Route path="/premium_review_3" exact={true} component={PR3}/>
        <Route path="/premium_review_4" exact={true} component={PR4}/>
        <Route path="/premium_review_5" exact={true} component={PR5}/>
        <Route path="/prn1" exact={true} component={PRN1}/>
        <Route path="/prn2" exact={true} component={PRN2}/>
        <Route path="/prn3" exact={true} component={PRN3}/>
        <Route path="/profile" exact={true} component={Profile}/>
        <Route
            path="/"
            render={({ location }) => {
              const queryParams = new URLSearchParams(location.search);
              const postid = queryParams.get('postid');
              const listid = queryParams.get('listid');
              const uid = queryParams.get('uid'); 

              if (postid) {
                // URL contains 'postid', render Post
                return <Post postid={postid} />;
              } 
              
              if (listid) {
                return <List listid={listid} />;
              }
              
              if (uid) {
                return <Profile uid={uid} />
              }

              // Handle other cases or redirect to a different page
              return <Redirect to="/about" />;
            }}
          />
      </Switch>
      </Suspense>
    </LastLocationProvider>
  </Router>
  );
}

export default App;
