import React, {useState} from 'react';
import ReactGA from 'react-ga';
import './App.css';
/*
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
*/

function About() {

    const iosopen = () => {
        ReactGA.event({
            category: 'Interaction',
            action: 'Opened the iOS download page(consumer_n_v3)'
        });
        window.open("https://apps.apple.com/us/app/%ED%94%BD%EA%B8%80/id1542494927");
    }

    const androidopen = () => {
        ReactGA.event({
            category: 'Interaction',
            action: 'Opened the Android download page(consumer_n_v3)'
        });
        window.open("https://play.google.com/store/apps/details?id=com.pickgleapp");
    }

    const linkopen = () => {
      
      ReactGA.event({
          category: 'Interaction',
          action: 'Opened the registration page(consumer_n_v3)'
      });
      
      window.open("https://tally.so/r/n07LrN");
      
  }



    return (
      <>
        <div className="about_body">
          <div className="about_form">
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp3%2F1.png?alt=media&token=15b7003f-0411-4db4-a19b-071735d94cfa&_gl=1*1lwxi46*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE5NTYyLjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp3%2F2.png?alt=media&token=0ac6322c-9d9b-4c29-acaa-713dab6f64e1&_gl=1*1co7uo7*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE5NTcyLjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp3%2F3.png?alt=media&token=3ae8cb3d-80f7-4786-a6a3-96a508cec484&_gl=1*1xpc5sy*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE5NTgzLjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp3%2F4.png?alt=media&token=82da3577-d5be-4988-b03f-eba9057d4522&_gl=1*1qfwqng*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE5NTkzLjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp3%2F5.png?alt=media&token=7c714e30-fa30-4348-b7bf-18e343b01785&_gl=1*1k6u7bp*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE5NjEzLjAuMC4w"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_3"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping%2Flp3%2F6.png?alt=media&token=0108f95d-2055-4d21-a010-c05040df9187&_gl=1*1qdycv8*_ga*MjAwNzIyMTkwMS4xNjY0OTY3MjQ2*_ga_CW55HF8NVT*MTY4NTUxNzgwMi40NDEuMS4xNjg1NTE5NjMyLjAuMC4w"
                alt="pickgle intro"
              ></img>
            <div className="write_forms_buttons2">
              <button
                className="about_button_focus_2"
                onClick={linkopen}
              >
                프로그램 사전 등록하기
              </button>
              <button
                className="about_button"
                onClick={iosopen}
              >
                <img className="about_logo" src = "https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/apple.png?alt=media&token=6a3c7745-3491-4f33-921e-b7c79cc0de56"/>
                App Store
              </button>
              <button className="about_button" onClick={androidopen}>
                <img className="about_logo" src = "https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/googleplay.png?alt=media&token=6783ccfe-0516-45ce-8624-a3a18fde81af"/>
                Google Play
              </button>
            </div>
          </div>
        </div>
      </>
    );
}

export default About;