import React, {useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import "firebase/functions";
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMedal,faStar, faPen, faHeart } from '@fortawesome/free-solid-svg-icons'

const Level = React.memo((props) => {

  const level = props.level;

  if (level===null) {
      return null;
  }

  if (level==='bronze') {
      return (
          <FontAwesomeIcon icon={faMedal} color='brown' className='web_badge'/>
      )
  } else if (level==='silver') {
      return (
          <FontAwesomeIcon icon={faMedal} color='silver' className='web_badge'/>
      )
  } else if (level==='gold') {
      return (
          <FontAwesomeIcon icon={faMedal} color='gold' className='web_badge'/>
      )
  } else if (level==='star') {
      return (
          <FontAwesomeIcon icon={faStar} color='gold' className='web_badge'/>
      )
  } else {
      return (
          <FontAwesomeIcon icon={faStar} color='orange' className='web_badge'/>
      )
  }
})
const TopPanel = (props) => {
  const userinfo = props.userinfo;
  const userbadge = props.userbadge;

  return (
    <div className='web_profile_panel'>
      <img className='web_profile_image' src={userinfo.profile_thumbnail}/>
      <div className='web_info_panel'>
        <div className='web_profile_info'>
          <div className='web_profile_name'>
            <div className='web_nickname'>
              {userinfo.nickname}
            </div>
            <div className='web_intro'>
              {userinfo.intro}
            </div>
          </div>
          <div className='web_profile_level'>
            <div className='web_level_button_row'>
                <span className='web_level_text'>
                  Lv.
                </span>
                <Level level={userinfo.level}/>
                <span className='web_level_text'>
                  뱃지
                </span>
                <span className='web_badge_count'>
                  {userbadge}
                </span>
            </div>
          </div>  
        </div>
      </div>
    </div>
  )
}

const StatsRow = (props) => {
  const stats = props.stats;
  const listinfo = props.listinfo;

  return (
    <div className='web_stats_row'>
      <FontAwesomeIcon icon={faPen} className={listinfo.list_image?'web_stats_white':'web_stats_black'}/>
      <span className={listinfo.list_image?'web_stats_text_white':'web_stats_text_black'}>
        {stats.postcount}
      </span>
      {" "}
      <FontAwesomeIcon icon={faHeart} className={listinfo.list_image?'web_stats_white':'web_stats_black'}/>
      <span className={listinfo.list_image?'web_stats_text_white':'web_stats_text_black'}>
        {stats.recocount}
      </span>
    </div>
  )
} 

const ListItemView = (props) => {
  const listinfo = props.listinfo;
  const stats = props.stats;

  return (
    <div className='web_content_row'>
      <div className='web_left_panel'>
        <div className={listinfo.list_image?'web_profile_list_title_w':'web_profile_list_title'}>
          {listinfo.list_name}
        </div>
      </div>
      <div className='web_lower_container'>
        <StatsRow stats={stats} listinfo={listinfo} />
      </div>
    </div>
  )
}

const ListItem = (props) => {
  const listinfo = props.listinfo;
  const stats = props.stats;

  return listinfo.list_image ? (
      <div
        className="web_post_preview"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${listinfo.list_image})`,
          backgroundSize: 'cover', // Maintain image aspect ratio
          backgroundPosition: 'center', // Center the image
        }}
      >
        <ListItemView listinfo={listinfo} stats={stats} />
      </div>
  ) : (
    <div className="web_post_preview">
      <ListItemView listinfo={listinfo} stats={stats} />
    </div>
  );
}

function Profile(props) {

  const [ data, setData ] = useState({loading:true, data:null})
  const history = useHistory();
  const uid = props.uid;



  const appInstallClick = () => {
    const userAgent = navigator.userAgent;

    if (userAgent && /android/i.test(userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=com.pickgleapp', '_blank')
    } else if (userAgent && /iPad|iPhone|iPod/i.test(userAgent)) {
      window.open('https://apps.apple.com/us/app/%ED%94%BD%EA%B8%80/id1542494927', '_blank')
    } else {
      window.open('bit.ly/3HmEQIN', '_blank');
    }
    ReactGA.event({
      category: 'Interaction',
      action: 'Opened the download link from profile link'
    });
  }

  useEffect(() => {
    const profileQuery = firebase.app().functions('asia-northeast3').httpsCallable('profileInfoHandler');
    
    profileQuery({ uid: uid })
      .then(result => {
        if (result.data.userinfo) {
          setData({loading:false,data:result.data})
        } else {
          history.push('/about');
        }
      })
      .catch(error => {
        console.error('Error calling Firebase function:', error);
        history.push('/about');
      });

      ReactGA.event({
        category: 'Interaction',
        action: 'Opened the profile link'
      });
  }, []);

    if (data.loading) {
      return (
        <div className="layer_spinner"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
      )
    }

    return (
      <>
        <div className="web_profile">
          <TopPanel userinfo={data.data.userinfo} userbadge={data.data.userbadge}/>
          <div className='web_profile_list_container'>
            {data.data.listinfo.sort((a, b) => (a.list_stats.postcount > b.list_stats.postcount) ? -1 : 1 ).map(li=>
              <ListItem listinfo={li.list_details} stats={li.list_stats} key={li.list_details.listid}/>
            )
            }
          </div>
          <div className='web_floating_action_button_container'>
            <div className='web_floating_action_button_text'>
              픽글 앱을 설치하시고 모든 기능을 사용해 보세요!
            </div>
            <button className='web_floating_button' onClick={appInstallClick}>
              앱 설치하러 가기
            </button>
          </div>
        </div>
      </>
    );
}

export default Profile;