import React, {useState} from 'react';
import ReactGA from 'react-ga';
import './App.css';
/*
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';

import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
*/

function About() {

    const iosopen = () => {
        ReactGA.event({
            category: 'Interaction',
            action: 'Opened the iOS download page'
        });
        window.open("https://apps.apple.com/us/app/%ED%94%BD%EA%B8%80/id1542494927");
    }

    const androidopen = () => {
        ReactGA.event({
            category: 'Interaction',
            action: 'Opened the Android download page'
        });
        window.open("https://play.google.com/store/apps/details?id=com.pickgleapp");
    }


    return (
      <>
        <div className="about_body">
          <div className="about_form">
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pretotyping_20230425_3.jpg?alt=media&token=f4334550-a942-4d2f-9b67-fc035619320f"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pe202301062.jpeg?alt=media&token=82083635-2090-4903-aa9e-ed6b8dedefba"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pe202301063.jpeg?alt=media&token=a1f42958-05f0-41a9-b76a-17da6c3b0424"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pe202301044.jpeg?alt=media&token=7e195c6c-c249-4145-9816-c4bbcaf5ba38"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pe202301045.jpeg?alt=media&token=a0108688-ef97-4da0-9ce8-39d4016d3939"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pe202301046.jpeg?alt=media&token=737631da-b2e7-48cc-be7a-237aed18667e"
                alt="pickgle intro"
              ></img>
              <img
                className="about_image_2"
                src="https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/pe202301047.png?alt=media&token=dbbe0534-b326-4797-9aa5-838985433a69"
                alt="pickgle intro"
              ></img>
            <div className="write_forms_buttons">
              <button
                className="about_button_focus"
                onClick={iosopen}
              >
                <img className="about_logo" src = "https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/apple.png?alt=media&token=6a3c7745-3491-4f33-921e-b7c79cc0de56"/>
                App Store
              </button>
              <button className="about_button_focus" onClick={androidopen}>
                <img className="about_logo" src = "https://firebasestorage.googleapis.com/v0/b/pickgle-new.appspot.com/o/googleplay.png?alt=media&token=6783ccfe-0516-45ce-8624-a3a18fde81af"/>
                Google Play
              </button>
            </div>
          </div>
        </div>
      </>
    );
}

export default About;